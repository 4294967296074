import tools from './tools'
import loader from './loader'

$(function () {
    tools.launchTac()
    loader.inputPasswordLoader()
    $('body').on('click', '#consent-edit', function () {
        $('body #tarteaucitron').slideDown('slow').css('opacity', 1).css('display', 'block')
    })
    // launch modal
    $('body').on('click', '.btn-load-modal', function (e) {
        e.preventDefault()
        e.stopImmediatePropagation()

        const $btn = $(this)
        const url = $btn.data('url')

        if (url == undefined) {
            console.log('click on .btn-load-modal but data-url is undefined :', $btn)

            return
        }

        tools.loadModal(url)
    })

    // Click to .btn-url
    $(document).on('click touch', '.btn-url', function (e) {
        loader.viewLoader($(this), e)
    })

    /**
     * @inheritdoc Submit form with ajax process
     * @version 1.1.0
     */
    $('body').on('submit', 'form:not(.n-ajax)', function (e) {
        e.preventDefault()
        console.log('form is submit ?')
        const $form = $(this)
        const form = document.getElementById($form.attr('id'))
        const formData = new FormData(form)
        const url = $form.data('url')
        const formContainer = $form.data('container')
        let isModal = $form.data('modal')
        let successContainer = null
        if (isModal != undefined) {
            isModal = true
            successContainer = $form.data('success-container')
            if (successContainer == undefined) {
                successContainer = null
            }
        }

        let $formContainer = $('#page-container')

        if (formContainer != undefined) {
            $formContainer = $('#' + formContainer)
        }

        tools.addToDebug('Form #' + $form.attr('id') + ' is submitted on ' + url)

        $.post({
            url: url,
            data: formData,
            contentType: false,
            processData: false,
            success: function (response) {
                console.log(response.state, isModal)
                if (response.state == undefined && isModal == undefined) {
                    console.log('not state loading')
                    $formContainer.html(response)
                    tools.init()

                    return
                }
                
                if (response.state == false) {
                    tools.addToDebug('The submitted form #' + $form.attr('id') + ' return a false statement', 'danger')
                    $formContainer.html(response.content)

                    tools.init()

                    return
                }
                tools.addToDebug('The submitted form #' + $form.attr('id') + ' return a good statement', 'success')

                if (isModal === true && response.isValid == true) {
                    $form.closest('.modal').hide('slow', function () {
                        $('.modal-backdrop, .jquery-modal.blocker').slideUp('slow')
                        $('body').removeClass('modal-open').css('padding-right', '0px').css('cursor', 'unset').css('overflow', 'unset')
                        $(this).remove()
                    })
                }

                if ($form.data('success-url') != undefined) {
                    tools.loadContent($formContainer, $form.data('success-url'))

                    return
                }

                if ($form.data('close-modal') != undefined) {
                    $('.modal-wrapper-main').hide('slow')
                    $('.' + $form.data('close-modal')).fadeOut('slow', function () {
                        $(this).remove()
                    })
                }

                tools.addToDebug('No action are defined after submitted form and return good state')
            }
        })
    })


    // clique sur un icône 'oeil'
    $('body').on('click', '.ico-field', function () {
        const $elm = $(this)
        const $parent = $elm.prev()
        if ($parent.attr('type') != 'password') {
            return
        }
        const $parentElm = document.getElementById($parent.attr('id'))
        $parentElm.type = 'text'
    })

    // Le curseur sort de l'icône 'oeil' 
    $('body').on('mouseleave', '.ico-field', function () {
        const $elm = $(this)
        const $parent = $elm.prev()
        if ($parent.attr('type') != 'text') {
            return
        }
        const $parentElm = document.getElementById($parent.attr('id'))
        $parentElm.type = 'password'
    })

    $('body').on('change blur focus keyup', '.form-control.password-rules', function () {
        console.log('.form-control.password-rules::change()|focus()|blur()')
        const $input = $(this)
        let password = $input.val()
        let rulesId = $input.data('rules-id')


        if (rulesId == undefined || rulesId == '') {
            return
        }

        let isValid = tools.verifyPasswordRules(password, rulesId)

        if (isValid) {
            $input.css('border-bottom', '2px solid green')
        } else {
            $input.css('border-bottom', '2px solid red')
        }
    })

    // autoloading elms
    tools.autoloading()
})