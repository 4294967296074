import tools from './tools'
import Encryption from './encryption'

$(document).ready(function () {
    // Submit the login form
    $('body').on('click', '#btn-submit-login-form', function (e) {
        e.preventDefault()
        const $submitBtn = $(this)
        if ($(this).children('.txt').children('.tools-loadingWrapper').length > 0) {
            return
        }
        $(this).parent().children('.form-error').html('')
        $(this).children('.txt').append(tools.loadingWrapper())

        const form = document.getElementById('loginForm')
        const formData = new FormData(form)

        const route = Routing.generate('app_frontend_login')

        const password = formData.get('password')

        let encryption = new Encryption()
        const encrypted = encryption.encrypt(password, 'my_value')
        formData.set('password', encrypted)

        $.post({
            url: route,
            data: formData,
            contentType: false,
            processData: false,
            success: function (response) {
                if (response.state == false) {
                    $submitBtn.children('.txt').children('.tools-loadingWrapper').remove()
                    $('.form-error').html('<i class="fal fa-exclamation-circle text-red"></i>&nbsp;' + response.error)

                    return
                }

                if (response.path == undefined) {
                    return
                }

                $submitBtn.children('.fal.fa-lock').removeClass('fa-clock').addClass('fa-lock-open').addClass('text-success')
            
                window.location.href = response.path
            }
        })

    })
})